<template>
  <div class="req-temp-container">
    <vs-card class="mt-0">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-normal text-xl md:text-2xl">Payment options</h3>
        </div>
      </div>
      <div>
        <div class="grid gap-y-6 gap-x-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div v-for="(card, key) in paymentOptions" :key="key" class="p-0 w-full flex">
            <vs-card class="shadow-none border mb-0">
              <div slot="header">
                <div class="flex w-10/12 items-center">
                  <S3ImageGenerator :is-base64="false" :document="card.icon" :custom-class="'payment-card'" :key="card.icon" class="h-6 w-auto" />
                  <h5 class="m-0 card__title ml-4 text-xl font-medium">{{ card.title }}</h5>
                </div>
              </div>
              <div>
                <div class="flex">
                  <div class="w-full">
                    <label class="font-light text-base body-grey leading-tight mb-2 block">Merchant service fee</label>
                    <p class="font-light text-base leading-tight text-dark-blue">{{ addZeroes(card.msf) }}%</p>
                  </div>
                </div>
                <div class="flex mt-5">
                  <div class="w-full">
                    <label class="vs-input--label">Surcharge to customer</label>
                    <money
                      id="surcharge"
                      v-bind="money2"
                      v-model.lazy="card.surcharge"
                      :name="`surcharge[${key}]`"
                      data-vv-as="surcharge"
                      v-validate="`required|decimal|max_value:${card.msf}`"
                      @keyup.native="checkForm"
                      @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                    ></money>
                    <span class="text-danger text-sm" v-show="errors.has(`surcharge[${key}]`)">{{ errors.first(`surcharge[${key}]`) }}</span>
                  </div>
                </div>
              </div>
            </vs-card>
          </div>
        </div>

        <vs-row class="block">
          <div class="button-section mb-2 lg:mb-4 mt-6 md:mt-8 lg:mt-12 pt-2">
            <vs-button class="primary lg:mr-6 mr-4" @click="updateSurcharge()" :disabled="isValidField" size="large" v-round >Save</vs-button>
            <div class="mid-blue text-sm font-normal underline pl-2" @click="$router.push({ name: 'settings' })">Cancel</div>
          </div>
        </vs-row>
      </div>
    </vs-card>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import { Money } from "v-money";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

const IconPath =  "";
export default {
  mixins: [leavePopUpMixin],
  components: { LeavePopup, Money, S3ImageGenerator },
  name: "PaymentOptions",

  data() {
    return {
      paymentOptions: [],
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      checkFormDirty: false,
      money2: {
        precision: 2,
        suffix: " %",
      },
    }
  },

  methods:{
    ...mapActions("paymentCardOption", ["fetchPaymentCardOptionByPartnerId", "updateSurchargeByPartnerId"]),

    async getPartnerCards() {
      this.$vs.loading();
      const partnerId  = this.user.userType == "admin" ? this.user._id : this.user.partnerId;

      await this.fetchPaymentCardOptionByPartnerId(partnerId).then((response) => {
        this.paymentOptions = response.data.data;
        this.paymentOptions = this.paymentOptions.map((card) => {
          card.msf = parseFloat(card.msf);
          card.surcharge = parseFloat(card.surcharge);
          return card;
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Card",
          text: ex.response.data.message,
          color: "danger",
          icon: "error",
          position: "top-right",
        });

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      }).finally(() => this.$vs.loading.close());
    },

    async updateSurcharge() {
      let isValid = await this.$validator.validateAll();

      if (!isValid) {
        this.$vs.notify({
          title: "Unable to save changes",
          text: "Sorry, the changes you made were not saved. Please try again.",
          color: "danger",
          icon: "error"
        });

        return false
      }

      this.$vs.loading();
      const paymentOptions = this.paymentOptions;

      await this.updateSurchargeByPartnerId(paymentOptions).then((response) => {
        this.$vs.loading.close();
        this.isSaved = true;
        this.$vs.notify({
          title: "Saved successfully",
          text: "Changes you made were saved successfully.",
          color: "success",
          icon:"check",
        });

        this.$router.push({ name: "settings" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Unable to save changes",
          text: "Sorry, the changes you made were not saved. Please try again.",
          color: "danger",
          icon: "error"
        });
      });
    },

    checkForm() {
      this.checkFormDirty = true;
    },

    addZeroes(num) {
      num = String(num);

      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", (to.name || undefined));
    } else {
      next();
    }
  },

  watch: {
    checkFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    isValidField() {
      let status = false;
      this.paymentOptions.map((item) => {
        if (item.surcharge === "" && item.surcharge <= 0 && item.surcharge <= item.msf) {
          status = true;
        }
      });

      return status;
    }
  },

  mounted() {
    this.getPartnerCards();
  }
}
</script>

